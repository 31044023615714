import { Link } from '@remix-run/react';

export interface WhyArchiProCardProps {
    title: string;
    icon?: React.ReactNode;
    description: string;
    to?: string;
    buttonText?: string;
}

const WhyArchiProCard = (props: WhyArchiProCardProps) => {
    const { title, description, to, buttonText, icon } = props;
    return (
        <div className="flex flex-col gap-5 border-t border-gray-200 pt-3">
            <div className="w-10.5 h-10.5 bg-brick-200 rounded-full flex items-center justify-center">
                {icon}
            </div>

            <div className="flex flex-col gap-7.5">
                <div className="flex flex-col">
                    <div className="pb-4 text-lg tracking-[0.025rem] leading-[130%] md:pb-0 md:pr-16 inline">
                        <h3 className="font-medium inline">{`${title} - `}</h3>
                        {description}
                    </div>
                </div>
                {to && (
                    <Link
                        to={to}
                        className="text-base font-medium w-fit"
                        style={{
                            textDecoration: 'underline',
                            textUnderlineOffset: '0.5rem',
                        }}
                    >
                        {buttonText}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default WhyArchiProCard;
