import { useAppData } from '@modules/root';
import { BottomLinkSection } from '@rocco/ui/bottom-link-section/components/BottomLinkSection';
import { ReactComponent as LogoIcon } from '@rocco/icons/svg/logo.svg';

export const PageBottomSection = () => {
    const { bottomContent } = useAppData();

    return (
        <BottomLinkSection
            whyArchiPro={bottomContent?.whyArchiPro.map((item) => ({
                ...item,
                icon: getIcon(),
            }))}
            bottomLink={bottomContent?.bottomLink}
        />
    );
};

// TODO: Add icons for other icons if any
const getIcon = () => {
    return <LogoIcon className="size-5" />;
};
