import { useAtomValue } from 'jotai';
import LinkCard, { type LinkCardProps } from './LinkCard';
import WhyArchiProCard, { type WhyArchiProCardProps } from './WhyArchiProCard';

import { uiFooterTrackingConfig } from '@rocco/ui/website-footer/components/Footer';

export const BottomLinkSection = ({
    whyArchiPro,
    bottomLink,
}: {
    whyArchiPro?: WhyArchiProCardProps[];
    bottomLink?: LinkCardProps[];
}) => {
    const footerTrackingConfig = useAtomValue(uiFooterTrackingConfig);

    return (
        <>
            {whyArchiPro && whyArchiPro.length > 0 && (
                <section className="w-full qhd:mx-auto qhd:max-w-[1920px]">
                    <div className="flex flex-col gap-10 px-6 md:px-12">
                        <h3 className="text-lg leading-[1.3] tracking-[0.025rem] font-medium">
                            <span>Why ArchiPro?</span>
                        </h3>

                        <div className="grid gap-5 grid-cols-1 md:grid-cols-4">
                            {whyArchiPro.map((card, index) => (
                                <WhyArchiProCard
                                    key={`why_archipro_card_${card.title}_${index}`}
                                    title={card.title}
                                    description={card.description}
                                    to={card.to}
                                    buttonText={card.buttonText}
                                    icon={card.icon}
                                />
                            ))}
                        </div>
                    </div>
                </section>
            )}
            {bottomLink && bottomLink.length > 0 && (
                <section className="w-full qhd:mx-auto qhd:max-w-[1920px]">
                    <div className="flex flex-col gap-[6rem] px-6 md:px-12">
                        <div className="flex w-full flex-col flex-wrap items-stretch justify-center gap-6 md:gap-5 md:flex-row">
                            {bottomLink.map((card, index) => (
                                <LinkCard
                                    key={`link_card_${card.title}_${card.variant}_${index}`}
                                    title={card.title}
                                    description={card.description}
                                    variant={card.variant}
                                    to={card.to}
                                    onClick={() =>
                                        footerTrackingConfig?.onBottomLinkSectionCardClick?.(
                                            index,
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};
