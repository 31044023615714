import { Link } from '@remix-run/react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { Button } from '@rocco/components/button';

import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';

// define the variant of cards
const _linkCardVariants = cva(
    ['flex-1', 'rounded-2xl', 'p-8', 'md:px-15', 'md:py-13'],
    {
        variants: {
            variant: {
                gray: 'bg-gray-100',
                brick: 'bg-brick-800',
            },
        },
        defaultVariants: {
            variant: 'gray',
        },
    },
);

// infer variants from cva
export interface LinkCardProps extends VariantProps<typeof _linkCardVariants> {
    title: string;
    description: string;
    to: string;
    buttonText?: string;
    onClick?: () => void;
}

const LinkCard = (props: LinkCardProps) => {
    const { title, description, variant, to, buttonText, onClick } = props;
    return (
        <div className={_linkCardVariants({ variant })}>
            <div className="flex h-full flex-col justify-between">
                <h3 className="pb-13 text-8 md:text-16 leading-[110%] tracking-[-0.06rem]">
                    {title}
                </h3>
                <div className="flex min-h-[13.8rem] flex-col flex-wrap justify-between">
                    <div className="flex flex-col gap-5 max-w-[27.5rem]">
                        <p className="pb-4 text-lg tracking-[0.025rem] leading-[130%] md:pb-0">
                            {description}
                        </p>
                        <Link
                            to={to}
                            className="text-base font-medium w-fit"
                            style={{
                                textDecoration: 'underline',
                                textUnderlineOffset: '0.5rem',
                            }}
                        >
                            Learn More
                        </Link>
                    </div>
                    <div>
                        <Button
                            color={variant === 'brick' ? 'glassy' : 'primary'}
                            inverted={variant === 'brick'}
                            weight="default"
                            size={'larger'}
                            onClick={onClick}
                            asChild
                        >
                            <Link to={to}>
                                {buttonText ?? title}
                                <ArrowRight className="ml-2.5 w-5" />
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkCard;
